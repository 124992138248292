import React, { Component } from 'react'
import PropTypes from "prop-types"
// import ReactDOM from 'react-dom'
import classnames from "classnames"
// import { withStyles } from "@material-ui/core/styles";
import './ContactCols.scss'

import ScrollOutUtils from "../../classes/ScrollOutUtils"
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper"
import ContactItem from "../ContactColsItems/ContactItem"


class ContactCols extends Component 
{
	constructor(props) 
	{
		super(props)
		
		// let style = this.props.style || {}
		
		this.state = {
		}

		// this.scrollUtils = new ScrollUtils()
	}

	componentDidMount()
	{
		ScrollOutUtils.init()
		// ScrollOut({
		// 	threshold: 0.5,
		// 	once: true,
		// });
	}

	getChildren = (childType, contact_form_id) => {
		let children = []
		let list = this.props.children || []

		for (let idx = 0; idx<list.length; idx++)
		{
			let child = list[idx]
			let thisChildType = child.type.toString()
			
			if ( childType.toLowerCase() !== thisChildType.toLowerCase() )
			{
				console.log(`Error: Invalid child type "${thisChildType}"! In ContactCols "${this.props.name}" only children of type "${childType}" are allowed! Child ignored:`, child)
				continue
			}
			
			switch ( thisChildType )
			{
				case "contactItem":
					children.push( <ContactItem key={"ContactItem"+idx} contact_form_id={contact_form_id} onContactPersonCB={this.props.onContactPersonCB} {... child} /> )
					break
				default:
					console.log(`%cContactCols :: Warning: Module "${thisChildType}" needs implementation!`, 'color: #f00;')
					break
			}
		}

		return children
	}

	render() 
	{
		// console.log(">>>>>>>>>> ContactCols ::\n\tthis.props=", this.props, "\n\tthis.state=", this.state)

		let contact_form_id = this.props.contact_form_id || -1

		if ( !this.props.onContactPersonCB )
		{
			console.error("################################################\nContactCols >> Error: this.props.onContactPersonCB not set!!!")
			return (<></>)
		}

		let children = this.getChildren( "contactItem", contact_form_id )

		return (
			<ModuleWrapper className={classnames("ContactCols")} {... this.props}>
				<div className="main-container">
					{children}
				</div>
			</ModuleWrapper>
		)
	}
}

ContactCols.defaultProps = {
    // name: "",
    // primary: false,
    // secondary: false,
	contact_form_id: -1,
	onContactPersonCB: null,
}

ContactCols.propTypes = {
    // name: PropTypes.string,
    // primary: PropTypes.bool,
    // secondary: PropTypes.bool,
	contact_form_id: PropTypes.number,
	onContactPersonCB: PropTypes.func,
}

export default ContactCols
