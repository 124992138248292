import React, { Component } from 'react'
// import ReactDOM from 'react-dom'
import classnames from "classnames"
// import { withStyles } from "@material-ui/core/styles"
import './TextImage2Cols.scss'

import PathUtils from '../../classes/PathUtils'
// import LayoutUtils from "../../classes/LayoutUtils"
import ScrollOutUtils from "../../classes/ScrollOutUtils"
import ScrollUtils from "../../classes/ScrollUtils"

// Material-UI Dokumentation
// 	+ https://material-ui.com/components/text-fields/#customized-inputs
// 	+ https://material-ui.com/customization/components/
import Button from '@material-ui/core/Button'
import Title from "../Title/Title"
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper"


/*
................................................................
... import { withStyles } from "@material-ui/core/styles" und 
... export default withStyles(styles)(TextImage2Cols)
... müssen noch eingeschaltet werden
................................................................
const styles = theme => ({
	root: {
		background: theme.palette.primary.main,

		'& .MuiInputLabel-filled': {
			color: '#303DA0'
		},
		'& .MuiFilledInput-input': {
			color: '#282D37'
		},
		'& .MuiFilledInput-root': {
			borderRadius: "5px 5px 0 0",
			background: 'rgba(255, 255, 255, 0.8)',
			'&:hover': {
				background: '#fff',
			}
		},
		'& .MuiFilledInput-underline:hover:before': {
			borderBottom: "1px solid #1B0E7B"
		},
		'& .MuiFilledInput-underline:before': {
			borderBottom: "1px solid rgba(27, 14, 123, 0.45)"
		},
		'& .MuiFilledInput-underline:after': {
			borderBottom: "2px solid #1B0E7B"
		}
	}
});
*/


class TextImage2Cols extends Component 
{
	constructor(props) 
	{
		super(props)
		
		// let style = this.props.style || {}
		
		this.state = {
		}
		this.scrollUtils = new ScrollUtils()
	}

	componentDidMount()
	{
		ScrollOutUtils.init()
		// ScrollOut({
		// 	threshold: 0.5,
		// 	once: true,
		// })
	}

	onClick_button = (e) => {

		e.preventDefault()
		this.scrollUtils.performOnClick(e)
	}

	render() 
	{
		// console.log(">>>>>>>>>> TextImage2Cols ::\n\tthis.props=", this.props, "\n\tthis.state=", this.state)

		let pathUtils = new PathUtils()
		let data_style = this.props.style || {}
		let data = this.props.data || {}

		let img_pos = (data_style.imagePosition || "left").trim().toLowerCase()
		let img_src = data.imgSrc || null
		let img_alt = data.imgAlt || ""
		let subline = data.subline || ""
		let body = data.body || ""
		let button_text = data.cta || ""
		let button_href = data.ctaHref || ""
		
		let button_elem = null
		if ( button_text.length>0 && button_href.length>0 )
		{
			button_elem = (
				<Button id="cta" name="cta" className="cta" variant="contained" onClick={this.onClick_button} href={button_href}>
					{button_text}
				</Button>
			)
		}

		let img = ""
		if ( img_src != null )
		{
			let src = pathUtils.resolveImagePath( img_src )
			img = <img src={src} alt={img_alt} />
		}

		return (
			<ModuleWrapper className={classnames("TextImage2Cols")} {... this.props}>
				<div className={classnames("main-container", {"img-right": (img_pos === "right")})}>
					<div className="mobile-title-container" data-scroll>
						<Title embedded={true} animated={false} {... this.props} />
					</div>
					<div className="img-container" data-scroll>
						{img}
					</div>
					<div className="content-container" data-scroll>
						<div className="desktop-title-container">
							<Title embedded={true} animated={false} {... this.props} />
						</div>
						<div className="content" data-scroll>
							<div className="subline">
								{subline}
							</div>
							<div className="body">
								{body}
							</div>
							{button_elem}
						</div>
					</div>
				</div>
			</ModuleWrapper>
		)
	}
}

// TextImage2Cols.defaultProps = {
//     name: "",
//     primary: false,
//     secondary: false,
// }
//
// TextImage2Cols.propTypes = {
//     name: PropTypes.string,
//     primary: PropTypes.bool,
//     secondary: PropTypes.bool,
// }

export default TextImage2Cols
// export default withStyles(styles)(TextImage2Cols)
