import React, { Component, lazy, Suspense } from 'react'
import classnames from 'classnames'
import './VideoTop.scss'

import PathUtils from '../../classes/PathUtils'

import ArrowDown from '../../assets/arrow_down.js'
import PlayIcon from '../../assets/play.svg'
import PauseIcon from '../../assets/pause.svg'

const VideoComponent = lazy(() => import('./VideoComponent'))

class VideoTop extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			isMobileView: false,
			isVideoPaused: false
		}
		this.videoRef = React.createRef()
		this.restartVideo = false
	}

	toggleVideoPlayPause = () => {
		this.setState(prevState => ({
			isVideoPaused: !prevState.isVideoPaused
		}))
	}

	getMediaSource(source)
	{
		let res = source
		if ( typeof(source) === "object" )
		{
			res = ( this.state.isMobileView ? source.mobile : source.desktop ) || ""
		}
		return res
	}

	render()
	{
		let pathUtils = new PathUtils()
		let data = this.props.data || {}
		let cover = data.cover || {}

		let cover_color = cover.color || ""
		let cover_opacity = parseFloat( cover.opacity || "1.0" )
		let title = data.title || ""
		let subtitle = data.subtitle || ""
		let hasNoTitle = ( title.length<=0 && subtitle.length<=0 )
		let videoPoster = this.getMediaSource( data.videoPoster || "" )
		let posterSrc = pathUtils.resolveImagePath(videoPoster)

		return (
			<>
				<div className="VideoTop">
					<div className="video-container">
						<img className={classnames("video-fallback")} src={posterSrc} alt="" />
						<Suspense fallback={<div></div>}>
							<VideoComponent
								{...this.props}
								isPaused={this.state.isVideoPaused}
							/>
						</Suspense>
						<div className="cover" style={{background: cover_color, opacity: cover_opacity}}></div>

						{/* Floating Pause/Play Button */}
						<button
							className={classnames("video-pause-play-btn", {
								"paused": this.state.isVideoPaused
							})}
							onClick={this.toggleVideoPlayPause}
						>
							{this.state.isVideoPaused ?
								<img src={PlayIcon} alt="Video pausieren"/> :
								<img src={PauseIcon} alt="Video fortsetzen"/>}
						</button>
					</div>
					<div className="content">
						<div className={classnames("title-main-container", {"hidden": hasNoTitle})}>
							<h1 dangerouslySetInnerHTML={{ __html: title }} />
							<h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
						</div>
						<ArrowDown className="arrow-down" />
					</div>
				</div>
			</>
		)
	}
}

export default VideoTop