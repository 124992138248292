import React, { Component } from 'react'
// import ReactDOM from 'react-dom'
import classnames from "classnames"
// import { withStyles } from "@material-ui/core/styles";
import './ContainerCols.scss'

// import LayoutUtils from "../../classes/LayoutUtils"
// import ScrollUtils from "../../classes/ScrollUtils"
import ScrollOutUtils from "../../classes/ScrollOutUtils"
// import ScrollableAnchor from 'react-scrollable-anchor'
import Title from "../Title/Title"
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper"
import BoldNumberItem from "../ContainerColsItems/BoldNumberItem"


class ContainerCols extends Component 
{
	constructor(props) 
	{
		super(props)
		
		// let style = this.props.style || {}
		
		this.state = {
		}
	}

	componentDidMount()
	{
		ScrollOutUtils.init()
		// ScrollOut({
		// 	threshold: 0.5,
		// 	once: true,
		// });
	}

	getChildren = (childType) => {
		let children = []
		let list = this.props.children || []

		for (let idx = 0; idx < list.length; idx++)
		{
			let child = list[idx]
			let thisChildType = child.type.toString()
			
			if ( childType.toLowerCase() !== thisChildType.toLowerCase() )
			{
				console.log(`Error: Invalid child type "${thisChildType}"! In ContainerCols "${this.props.name}" only children of type "${childType}" are allowed! Child ignored:`, child)
				continue
			}
			
			switch ( thisChildType )
			{
				// case "featureItem":
				// 	children.push( <FeatureItem key={"FeatureItem"+idx} {... child} /> )
				// 	break
				case "boldNumberItem":
					children.push( <BoldNumberItem key={"FeatureItem"+idx} {... child} /> )
					break
				default:
					console.log(`%cContainerCols :: Warning: Module "${thisChildType}" needs implementation!`, 'color: #f00;')
					break
			}
		}

		return children
	}

	render() 
	{
		// console.log(">>>>>>>>>> ContainerCols ::\n\tthis.props=", this.props, "\n\tthis.state=", this.state)

		// let layoutUtils = new LayoutUtils()
		// let padding = layoutUtils.getPadding("", this.props)

		let childType = ""
		try { childType = this.props.data.childrenType.toString() } catch (err) {}
		let children = this.getChildren( childType )

		return (
			<ModuleWrapper className={classnames("ContainerCols")} {... this.props}>
				<div className="main-container">
					<Title embedded={true} animated={false} {... this.props} />
					<div className="content">
						{/* <div class="item">child 1</div>
						<div class="item">child 2<br/>child 2</div>
						<div class="item">child 3<br/>child 3<br/>child 3</div>
						<div class="item">child 4<br/>child 2<br/>child 4<br/>child 4</div> */}
						{children}
					</div>
				</div>
			</ModuleWrapper>
		)
	}
}

// ContainerCols.defaultProps = {
//     name: "",
//     primary: false,
//     secondary: false,
// }
//
// ContainerCols.propTypes = {
//     name: PropTypes.string,
//     primary: PropTypes.bool,
//     secondary: PropTypes.bool,
// }

export default ContainerCols
