import React, {Component} from 'react'
import PropTypes from "prop-types"
// import ReactDOM from 'react-dom'
import classnames from "classnames"
import validator from 'validator'
import './ContactForm.scss'

// Material-UI Dokumentation
// 	+ https://material-ui.com/components/text-fields/#customized-inputs
// 	+ https://material-ui.com/customization/components/
import TextField from '@material-ui/core/TextField'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@material-ui/core/Button'

import axios from "axios"
import qs from 'qs'
// import ScrollOut from "scroll-out"
import ScrollOutUtils from "../../classes/ScrollOutUtils"
import EmailUtils from "../../classes/EmailUtils"
// import LayoutUtils from "../../classes/LayoutUtils"
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper"


const form_name_id = "your_name"
const form_email_id = "your_email"
const form_contact_id = "your_contact"
const form_question_id = "your_question"

class ContactForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ["err_" + form_name_id]: "",
            ["err_" + form_email_id]: "",
            ["err_" + form_contact_id]: "",
            ["err_" + form_question_id]: "",
            //
            ["input_" + form_name_id]: "",
            ["input_" + form_email_id]: "",
            ["input_" + form_contact_id]: "",
            ["input_" + form_question_id]: "",
            //
            setFocusOnInput: false,		// ... das erste inputField, dass ein Fehler enthält
            buttonLabel: "",
            disableButton: false,
        }

        this.inputRef_name = React.createRef()
        this.inputRef_email = React.createRef()
        this.inputRef_contact = React.createRef()
        this.inputRef_question = React.createRef()
        this.emailUtils = new EmailUtils()

        this.OLD_prop_selection = ""
    }

    componentDidMount() {
        ScrollOutUtils.init()
        // ScrollOut({
        // 	threshold: 0.5,
        // 	once: true,
        // })
    }

    componentDidUpdate() {
        // console.log("+++++ componentDidUpdate: in")
        if (this.OLD_prop_selection !== this.props.selection)		// this.props.selection kommt vom ContactCols-Modul, wenn man auf dem Kontakt-Bild klickt
        {
            // console.log("+++++ componentDidUpdate: ", this.OLD_prop_selection, "!==", this.props.selection, "=>", true)
            this.OLD_prop_selection = this.props.selection			// den übergebenen Wert speichern, damit man erkennen kann, ob einer neuer Wert über this.props.selection übermittelt wurde
            // this.state["input_"+form_contact_id] = this.props.selection
            this.setState({["input_" + form_contact_id]: this.props.selection})
        }
    }

    parseInputData = (data, defaultLabel, defaultPlaceholder) => {
        return {
            label: (data.label || defaultLabel),
            placeholder: (data.placeholder || defaultPlaceholder)
        }
    }

    getContactList = () => {

        let res = []

        let data = this.props.data || {}
        let contact_list = data.contact_list || []
        let idx = 1

        /*
        res.push( <MenuItem key={"keyID"+idx} value=""><em>Ansprechpartner wählen ...</em></MenuItem> )
        idx += 1
        */

        for (let each of contact_list) {
            let type = each.type || "unknown"
            if (type !== "contactItem") continue			// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> continue

            let each_data = each.data || {}
            let each_contact = each_data.contact || {}
            let fullName = (each_contact.fullName || "").trim()
            let email = (each_contact.email || "").trim()
            if (fullName.length <= 0 || email.length <= 0) continue	// >>>>>>>>>>>>>>>>>>>>>> continue
            res.push(
                <MenuItem key={"keyID" + idx} value={`${fullName}|${email}`}>{fullName}</MenuItem>
            )
            idx += 1
        }

        return res
    }

    onInputChange = (event) => {
        // console.log("Input changed ...", event.target.id, event.target.value)

        let data = {
            // errorMessage: "",	... nicht einschalten, weil es in validateInputValue(..) gesetzt wird
            ["input_" + event.target.id]: event.target.value
        }

        let result = this.validateInputValue(event.target.id, event.target.value, false)
        Object.assign(result, data)

        // console.log(">>>>>>>> ContactForm :: onInputChange : result=", result)
        this.setState(result)
    }

    onSelectionInputChange = (event) => {
        this.setState({["input_" + form_contact_id]: event.target.value})
    }

    validateInputValue = (input_id, input_str, execSetState = true) => {

        let result = {}

        switch (input_id) {
            case form_name_id:
                result["err_" + form_name_id] = (input_str.trim().length > 0
                        ? ""
                        : "Bitte Name eingeben!"
                )
                break

            case form_email_id:
                // result["err_"+form_email_id] = ( validator.isEmail(input_str.trim())
                // 	? ( this.emailUtils.hasValidEmailDomain(input_str)
                // 			? ""
                // 			: "Bitte eine 'deutschebahn.com'-E-Mail eingeben!"
                // 		)
                // 	: "Bitte eine 'deutschebahn.com'-E-Mail eingeben!"
                // )
                result["err_" + form_email_id] = (validator.isEmail(input_str.trim())
                        ? ""
                        : "Bitte eine E-Mail-Adresse eingeben!"
                )
                break

            case form_contact_id:
                result["err_" + form_contact_id] = (input_str.trim().length > 0
                        ? ""
                        : "Bitte einen Ansprechpartner auswählen!"
                )
                break

            case form_question_id:
                result["err_" + form_question_id] = (input_str.trim().length > 0
                        ? ""
                        : "Bitte eine Nachricht eingeben!"
                )
                break
            default:
                break
        }


        // console.log(">>>>>>>> ContactForm >> validateInputValue :: result=", result)
        if (execSetState) {
            this.setState(result)
        }
        return result
    }

    onSubmitHandler = (event) => {

        event.preventDefault()

        let action_url
        try {
            action_url = this.props.data.action
        } catch (e) {
            return
        }		// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!

        console.log(">>>>>>>>>>> ContactForm >> onSubmitHandler :: this.state:", this.state, action_url)
        let id_list = [form_name_id, form_email_id, form_contact_id, form_question_id]
        for (let each_id of id_list) {
            if (this.state["err_" + each_id] === "" && this.state["input_" + each_id].trim().length > 0) {
                continue
            } else {
                this.validateInputValue(each_id, this.state["input_" + each_id], true)
                switch (each_id) {
                    case form_name_id:
                        this.inputRef_name.current.focus()
                        return	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
                    case form_email_id:
                        this.inputRef_email.current.focus()
                        return	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
                    case form_contact_id:
                        this.inputRef_contact.current.focus()
                        return	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
                    case form_question_id:
                        this.inputRef_question.current.focus()
                        return	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
                    default:
                        console.log(`%cContactForm >> onSubmitHandler :: Error: Unknown input id '${each_id}'!`, 'color: #f00;')
                        return	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
                }
            }
        }

        // const data = new FormData(event.target)	..... mit Material-UI bekomme ich hier keine Werte zurück, deshalb erzeuge ich das data-Objekt per Hand
        const data = {
            name: this.state["input_" + form_name_id],
            email: this.state["input_" + form_email_id],
            contact: this.state["input_" + form_contact_id],
            question: this.state["input_" + form_question_id],
            action: "triebfahrzeugfuehrende_contact_form"
        }

        this.setState({buttonLabel: "Senden ..."})

        window._paq = window._paq || [];
        if (window._paq) {
            window._paq.push(['trackEvent', 'form', 'submit']);
        }

        axios({
            // url: `https://httpbin.org/post`,
            url: action_url,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: qs.stringify(data)
        }).then(res => {
            console.log("++ ContactForm >> onSubmitHandler :: ok", res)

            setTimeout(() => {
                this.setState({
                    buttonLabel: "Nachricht erfolgreich versendet",
                    disableButton: true
                })
            }, 500)
        }).catch(error => {
            console.log("++ ContactForm >> onSubmitHandler :: error", error)

            setTimeout(() => {
                this.setState({buttonLabel: ""})
            }, 500)
        })
    }

    render() {
        // console.log(">>>>>>>>>> ContactForm ::\n\tthis.props=", this.props, "\n\tthis.state=", this.state)

        let data = this.props.data || {}


        //-----------------------------------------
        /*
        ................. folgender Code in componentDidUpdate() verschoben
        let selected_contact = this.state["input_"+form_contact_id]
        if ( this.OLD_prop_selection !== this.props.selection )		// this.props.selection kommt vom ContactCols-Modul, wenn man auf dem Kontakt-Bild klickt
        {
            // console.log(this.OLD_prop_selection, "!==", this.props.selection, "=>", true)
            this.OLD_prop_selection = this.props.selection			// den übergebenen Wert speichern, damit man erkennen kann, ob einer neuer Wert über this.props.selection übermittelt wurde
            // this.state["input_"+form_contact_id] = this.props.selection
            this.setState( {["input_"+form_contact_id]: this.props.selection} )
            selected_contact = this.props.selection
        }
        */
        let selected_contact = this.state["input_" + form_contact_id]
        //-----------------------------------------

        let input_name = this.parseInputData(data.input_name || {}, "Dein Name", "Max Mustermann")
        let input_email = this.parseInputData(data.input_email || {}, "Deine E-Mail Adresse", "Max.Mustermann@deutschebahn.com")
        let input_contact = this.parseInputData(data.input_contact || {}, "Ansprechpartner", "")
        let input_question = this.parseInputData(data.input_question || {}, "Deine Nachricht", "")
        let button_tmp_label = data.buttonCTA || "Senden"
        let button_text = (this.state.buttonLabel.length > 0
                ? this.state.buttonLabel
                : button_tmp_label
        )

        let contact_list = this.getContactList()

        let err_name = this.state["err_" + form_name_id]
        let err_email = this.state["err_" + form_email_id]
        let err_contact = this.state["err_" + form_contact_id]
        let err_question = this.state["err_" + form_question_id]

        return (
            <ModuleWrapper className="ContactForm" {...this.props}>
                <div className={classnames("form-container")}>
                    <form onSubmit={this.onSubmitHandler} autoComplete="off">
                        <div className="name-email-container" data-scroll>
                            <TextField
                                inputRef={this.inputRef_name}
                                error={err_name !== ""}
                                helperText={err_name !== "" ? err_name : ''}
                                className="your_name"
                                id="your_name"
                                name="your_name"
                                label={input_name.label}
                                placeholder={input_name.placeholder}
                                variant="filled"
                                onChange={this.onInputChange}
                                disabled={this.state.disableButton}
                                required={true}
                            />
                            <TextField
                                inputRef={this.inputRef_email}
                                error={err_email !== ""}
                                helperText={err_email !== "" ? err_email : ''}
                                className="your_email"
                                id="your_email"
                                name="your_email"
                                label={input_email.label}
                                placeholder={input_email.placeholder}
                                variant="filled"
                                onChange={this.onInputChange}
                                disabled={this.state.disableButton}
                                required={true}
                            />
                        </div>
                        <FormControl className="your_contact" variant="filled" disabled={this.state.disableButton}
                                     data-scroll>
                            <InputLabel htmlFor="contact-selection-id" id="contact-label-id">{input_contact.label} *</InputLabel>
                            <Select
                                labe={input_contact.label}
                                inputProps={{ id: "contact-selection-id" }}
                                inputRef={this.inputRef_contact}
                                value={selected_contact}
                                onChange={this.onSelectionInputChange}
                                IconComponent={IconExpandMore}
                                required={true}
                            >
                                {contact_list}
                            </Select>
                            <FormHelperText
                                className="Mui-error">{err_contact !== "" ? err_contact : ''}</FormHelperText> {/* hier muss man "Mui-error" per Hand einfügen, damit das entsprechende CSS aktiviert wird! */}
                        </FormControl>
                        <TextField
                            inputRef={this.inputRef_question}
                            error={err_question !== ""}
                            helperText={err_question !== "" ? err_question : ''}
                            className="your_question"
                            id="your_question"
                            name="your_question"
                            label={input_question.label}
                            placeholder={input_question.placeholder}
                            multiline
                            rows={6}
                            defaultValue=""
                            variant="filled"
                            onChange={this.onInputChange}
                            disabled={this.state.disableButton}
                            data-scroll
                            required={true}
                        />
                        {/* <input type="hidden" name="action" value="qosmo_contact_form" /> ......... siehe in this.onSubmitHandler() */}

                        <div className="button-container" data-scroll>
                            <small className="required-notice">* Pflichtfeld</small>
                            <Button type='submit' id="cta" name="cta" className="cta" variant="contained"
                                    disabled={this.state.disableButton}>
                                {button_text}
                            </Button>
                        </div>

                    </form>
                </div>
            </ModuleWrapper>
        )
    }
}

ContactForm.defaultProps = {
//	name: "",
//	primary: false,
//	secondary: false,
    selection: "",
}

ContactForm.propTypes = {
//	name: PropTypes.string,
//	primary: PropTypes.bool,
//	secondary: PropTypes.bool,
    selection: PropTypes.string
}

export default ContactForm
