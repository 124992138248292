
// import { goToAnchor, removeHash } from 'react-scrollable-anchor'
import { goToAnchor } from 'react-scrollable-anchor'

class ScrollUtils
{
	// constructor() {}

	getAnchorID = (module_props) => {

		let anchorID = null
		try 
		{
			let idAttr = module_props.id.toString().trim()
			if ( idAttr.length > 0 )
			{
				anchorID = idAttr
			}
		}
		catch (err)
		{}

		let props = {}
		if ( anchorID )
		{
			// props.id = `anchor_${anchorID}`
			props.id = this.formatAnchor(anchorID)
		}

		return props
	}

	formatAnchor = (anchorID) => { return `anchor_${anchorID}` }
	
	performOnClick = (event) => {

		event.preventDefault()

		// let href = event.target.getAttribute("href").trim()
		let href = event.target.getAttribute("href")
		if ( typeof(href)=="undefined" || href==null )
		{
			let elem = this.getParentElementWithHref( event.target )
			if ( !elem )
			{
				console.error("Error: Cannot find href attribute in element!", event.target)
				return
			}
			href = elem.getAttribute("href")
		}
		if ( href.startsWith("#") )
		{
			href = href.substring(1)
			// console.log(">>>>> performOnClick (intern):", href)
			goToAnchor(href, false)
			// removeHash() ... in QOSMO hat es keine Probleme gemacht, jetzt will die Lib nicht mehr Scrollen, wenn man removeHash() ausführt
		}
		else
		{
			// console.log(">>>>> performOnClick (intern):", href)
			window.open(href, '_blank').focus()
		}
	}

	// current_elem := event.target | getElementById() | getElementBy...
	//
	// wird z.B. benötigt, wenn man ein Material-Button verwendet, um an den richtigen event.target (A-Tag) zu kommen.
	// Wenn man z.B. auf ein Material-Button genau auf dem Label klickt, dann ist event.target das SPAN-Element und nicht der A-Tag
	//
	// let elem = this.getParentElementWithHref( e.target )
	// if ( elem ) {
	//		console.log( "href found:", elem.getAttribute("href") )
	// } else {
	//		console.log( "href not found!" )
	// }
	//
	getParentElementWithHref = (current_elem) => {
		let elem = current_elem
		while ( elem!=null && !elem.getAttribute('href') )
		{
			elem = elem.parentNode
		}
		return elem
	}
	
	getParentElementWithClass = (current_elem, className) => {
		let elem = current_elem
		while ( elem!=null && !elem.classList.contains(className) )
		{
			elem = elem.parentNode
		}
		return elem
	}
}

export default ScrollUtils