import React, { Component } from 'react'
import PropTypes from "prop-types"
import classnames from 'classnames'
import './ModuleWrapper.scss'

import LayoutUtil from '../../classes/LayoutUtils'
import ScrollUtils from "../../classes/ScrollUtils"
import BackgroundWrapper from "../BackgroundWrapper/BackgroundWrapper"


class ModuleWrapper extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	getBackgroundColor(props)
	{
		/*
		let backgroundStyle = props.style.background
		if ( typeof(backgroundStyle)!="undefined" && backgroundStyle!=null )
		{
			if ( /^\s*white\s*$/i.test(backgroundStyle) )
			{
				return "bg-white"
			}
			else if ( /^\s*transparent\s*$/i.test(backgroundStyle) )
			{
				return "bg-transparent"
			}
			return "bg-grey"		// default, if unknown color
		}
		*/
		return "bg-transparent"		// default, if color is undefined/null
	}

	getGradient(props)
	{
		/*
		let gradStyle = props.style.gradient
		if ( typeof(gradStyle)!="undefined" && gradStyle!=null )
		{
			if ( /^\s*top-down\s*$/i.test(gradStyle) )
			{
				return "gradTopDown"
			}
			else if ( /^\s*bottom-up\s*$/i.test(gradStyle) )
			{
				return "gradBottomUp"
			}
		}
		*/
		return ""					// default, if gradient not defined / null
	}

	render() 
	{
		// console.log(">>>>>>>>>>>>> ModuleWrapper :: this.props=", this.props)

		let layoutUtil = new LayoutUtil()
		
		let divBgColor = this.getBackgroundColor(this.props)
		let gradient = this.getGradient(this.props)
		let margin = layoutUtil.getMargin("moduleWrapper-", this.props)
		let anchor_props = (new ScrollUtils()).getAnchorID(this.props)

		let module = ( 
			<div className="module wrapper">
				{this.props.children}
			</div>
		)
		module = (  <div>{module}</div> );

		return (
			<div className={classnames(divBgColor, gradient)}>
				<BackgroundWrapper {... this.props}>
					<div id={`${anchor_props.id}`} />
					<div className={classnames(this.props.className, margin)}>
						{module}
					</div>
				</BackgroundWrapper>
			</div>
		)
	}
}

ModuleWrapper.defaultProps = {
	setAnchor: false
	// name: "",
	// primary: false,
	// secondary: false,
}

ModuleWrapper.propTypes = {
	setAnchor: PropTypes.bool,
	// name: PropTypes.string,
	// primary: PropTypes.bool,
	// secondary: PropTypes.bool,
}

export default ModuleWrapper
