import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from "prop-types"

import './MenuItem.scss'

import ScrollUtils from "../../classes/ScrollUtils"

// import icon_contact from "../../assets/icon_contact.svg"
import IconContact from "../../assets/icon-nav-contact"


class MenuItem extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	render() 
	{
		// console.log(">>>>>>>>>> MenuItem :: this.props=", this.props)

		let props = {}
		let scrollUtils = new ScrollUtils()
		let label
		let icon = null
		try 
		{
			let data = this.props.data
			let href = data.href || ""

			if ( href.trim().length !== 0  )
			{
				props.href = href
				props.rel="nofollow noopener noreferrer"
			}
			else
			{
				props.href = "#" + scrollUtils.formatAnchor(data.hashId)
			}
			if ( data.target ) { props.target = data.target }
			label = ( data.name && data.name.length>0 ? data.name : "MenuItem" )

			if ( typeof(this.props.style)!="undefined" && this.props.style!=null )
			{
				let style = this.props.style
				if ( typeof(style.button_type)!="undefined" && style.button_type!=null )
				{
					switch ( style.button_type.toLowerCase().trim() )
					{
						case "contact": 
							// icon = <img className="icon" src={icon_contact} alt="Contact" />
							icon = <IconContact className="icon" />
							break
						case "default":
						default:
							icon = null
							break
					}
				}
			}
		}
		catch (err) {}

		let menuItems_additional_cls = {
			"withIcon": (icon != null)
		}

		if ( this.props.onClickCB )
		{
			return (
				<a className={classnames("MenuItem", menuItems_additional_cls)} onClick={this.props.onClickCB} {... props}>{icon?icon:""}<span dangerouslySetInnerHTML={{ __html: label }} /></a>
			)
		}

		return (
			<a className={classnames("MenuItem", menuItems_additional_cls)} {... props}>{icon?icon:""}<span dangerouslySetInnerHTML={{ __html: label }} /></a>
		)
	}
}

MenuItem.defaultProps = {
	// name: "",
	// primary: false,
	// secondary: false,
	onClickCB: null
}

MenuItem.propTypes = {
	// name: PropTypes.string,
	// primary: PropTypes.bool,
	// secondary: PropTypes.bool,
	onClickCB: PropTypes.func
}

export default MenuItem
