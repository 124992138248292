import React from 'react';
// import logo from '../../logo.svg';
import './Home.scss';

// Material-UI Dokumentation
// 	+ https://material-ui.com/components/text-fields/#customized-inputs
// 	+ https://material-ui.com/customization/components/
//  + https://codesandbox.io/s/withstyles-example-n02ot?from-embed=&file=/src/index.js:62-173
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from '@material-ui/core/styles'
import NavigationBar from "../../components/NavigationBar/NavigationBar"
import VideoTop from "../../components/VideoTop/VideoTop"
import Footer from "../../components/Footer/Footer"
import Title from "../../components/Title/Title"
import ContainerCols from "../../components/ContainerCols/ContainerCols"
import ContactCols from "../../components/ContactCols/ContactCols"
import HighlightsSlider from "../../components/HighlightsSlider/HighlightsSlider"
import TestimonialsSlider from "../../components/TestimonialsSlider/TestimonialsSlider"
import TextImage2Cols from "../../components/TextImage2Cols/TextImage2Cols"
import ContactForm from "../../components/ContactForm/ContactForm"

import { connect } from 'react-redux'
import { api_get_request } from "../../store/actions"
import config from "../../_config/config"

import { configureAnchors } from "react-scrollable-anchor"
configureAnchors({ 
	offset: -100, 
	scrollDuration: 1000,
	keepLastAnchorHash: false
})

const theme = createTheme({
	palette: {
		primary: {
			// -----
			// ----- das sind nur Test-Farben ... "theme" wird zwar gesendet, aber zurzeit noch nicht verwendet
			// -----
			light: "#757ce8",
			main: "#3f50b5",
			dark: "#002884",
			contrastText: "#fff"
		}
	}
});

class Home extends React.Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {
			contact_data: ""
		}
	}

	componentDidMount() 
	{
		console.log("++ process.env.NODE_ENV:", process.env, process.env.NODE_ENV)
		switch ( config.api_target )
		{
			case "development":
				// this.props.api_get_request("page")		// ohne "/" am Ende, sonst funkt. der API-Call nicht!
				this.props.api_get_request("/")
				break

			case "production":
				// this.props.api_get_request("")
				this.props.api_get_request("/")			// mit "/" am Ende, sonst funkt. der API-Call nicht!
				break
				
			default:
				console.log(`%c>>>>>>>>>>>>> Home >> componentDidMount :: Error: Unknown api target '${config.api_target}'!`, "color: red;", "- config:", config)
				break
		}

		let hash = window.location.hash
		console.log(hash);

		if(hash.includes('anchor_')) {

			setTimeout(() => {
				let element = document.getElementById(hash.replace("#", ""));

				if (element) {
					console.log("JUMPING")
					element.scrollIntoViewIfNeeded(true);
				}
			}, 500);
		}

	}

	getContactList( error, data )
	{
		let res = []

		if ( error ) return res
		if ( typeof(data)!="undefined" && data!=null && Object.keys(data).length<=0 ) return res
		
		let children = data.children
		if ( typeof(children)=="undefined" || children==null ) return res

		for(let idx = 0; idx < children.length; idx++)
		{
			let module = children[idx]

			if ( module.type === "contactCols" )
			{
				for(let eachContact of module.children)
				{
					res.push( eachContact )
				}
			}
		}

		return res
	}

	getContactFormID( error, data )
	{
		let res = -1

		if ( error ) return res
		if ( typeof(data)!="undefined" && data!=null && Object.keys(data).length<=0 ) return res
		
		let children = data.children
		if ( typeof(children)=="undefined" || children==null ) return res

		for(let idx = 0; idx < children.length; idx++)
		{
			let module = children[idx]

			if ( module.type === "contactForm" )
			{
				return ( module.id || -1 )
			}
		}

		return res
	}

	onContactPersonSelected = (contact_data) => {
		// console.log("-----------> onContactPersonSelected", contact_data)
		this.setState( {contact_data: contact_data} )
	}

	getChildren( error, data, contact_list, contactFormID )
	{
		let res = []

		if ( error )
		{
			res.push(<div key={"childModule-1"} style={{width: "100%", background: "#fea8a8", color: "#000", padding: "5px 0", margin: "0 0 1px 0"}}>
				<b style={{color: "#000"}}>{error.message}</b><br/>
				<pre>{error.stack}</pre>
			</div>)
			return res		// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
		}

		if ( typeof(data)!="undefined" && data!=null && Object.keys(data).length<=0 )
		{
			// console.log("data is empty")
			return res		// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
		}

		let children = data.children
		if ( typeof(children)=="undefined" || children==null ) 
		{
			console.error("Error: Malformed data object! Children not found! data=", data)
			return res		// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
		}

		let num_topMenu = 0
		for(let idx = 0; idx < children.length; idx++)
		{
			let module = children[idx]
			switch ( module.type )
			{
				case "videoTop":
					res.push(<VideoTop key={"childModule"+idx} {... module} />)
					break
				case "title":
					res.push(<Title key={"childModule"+idx} {... module} />)
					break
				case "footer":
					res.push(<Footer key={"childModule"+idx} {... module} />)
					break
				case "containerCols":
					res.push(<ContainerCols key={"childModule"+idx} {... module} />)
					break
				case "contactCols":
					res.push(
						<ContactCols key={"childModule"+idx} contact_form_id={contactFormID} onContactPersonCB={this.onContactPersonSelected} {... module} />)
					break
				case "topMenu":
					if ( num_topMenu <= 0 )
					{
						num_topMenu += 1
						res.push(<NavigationBar key={"childModule"+idx} {... module} />)
					}
					else
					{
						console.log(`%cError: "%c${module.type}%c" can be used only once Unknown module!`, 'color: #f00', 'color: #ff0')
					}
					break
				case "highlightsSlider":
					res.push(<HighlightsSlider key={"childModule"+idx} {... module} />)
					break
				case "testimonialsSlider":
					res.push(<TestimonialsSlider key={"childModule"+idx} {... module} />)
					break
				case "textImage2Cols":
					res.push(<TextImage2Cols key={"childModule"+idx} {... module} />)
					break
				case "contactForm":
					if ( typeof(module.data)!="undefined" && module.data!=null )
					{
						module.data.contact_list = contact_list
					}
					res.push(<ContactForm key={"childModule"+idx} selection={this.state.contact_data} {... module} />)
					break

				// case "newModule":
				// 	res.push(<div key={"childModule"+idx} style={{width: "100%", background: "#ffe38f", color: "#000", padding: "5px 0", margin: "0 0 1px 0"}}><div className="wrapper">Module "<b style={{color: "#000"}}><i>{module.type}</i></b>" needs implementation</div></div>)
				// 	break

				default:
					console.log(`%cError: Unknown module! name: "%c${module.name}%c", type: "%c${module.type}%c"`, 'color: #f00', 'color: #ff0', 'color: #f00', 'color: #ff0', 'color: #f00')
			}
		}
		return res
	}

	render() 
	{
		let data = this.props.data
		let error = this.props.error
		// let isLoadingData = this.props.isLoadingData

		// console.log(">>>>>>>>>>>>>> this.props:", this.props)
		let contactList = this.getContactList( error, data )
		let contactFormID = this.getContactFormID( error, data )
		let children = this.getChildren( error, data, contactList, contactFormID )

		return (
			<ThemeProvider theme={theme}>
				<div className="Home">
					{children}
				</div>
			</ThemeProvider>
		);
	}
}

// export default Home;
const mapStateToProps = ({ data = {}, error = null, isLoadingData = false }) => ({
	data,
	error,
	isLoadingData
});
export default connect(
	mapStateToProps,
	{
		api_get_request
	}
)(Home)